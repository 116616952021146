import React from 'react'

import an from './images/analisis vaksin.jpeg'
import cuaca from './images/cuaca.jpg'
import padi from './images/fs.jpg'
import ds1 from './images/ds1.png'
import ds2 from './images/ds2.png'
import monyet from './images/mongkeypox.jpeg'


function Portfolio() {
  return (
    <div id='portfolio' className='bg-zinc-900 pt-20'>
        <h1 className='text-6xl text-center font-semibold text-green-800 mb-5 lg:mb-2'>Portfolio</h1>
        <h1 className='text-2xl text-center font-semibold text-white mb-14 lg:font-normal'>this is my personal project</h1>
        <div className='grid grid-flow-row lg:grid-flow-col lg:grid-cols-3 lg:grid-rows-2 lg:px-60'>

           <a href="https://github.com/zaim1609/analisis-sentimen-naivebayes/blob/main/analisis_sentimen.ipynb">
            <div className=' mb-5'>
                <img src={an} alt="" className='object-cover h-96 w-96 mx-auto' />
            </div>
            </a>

            <a href="https://github.com/zaim1609/time-series-weather/blob/main/time_series_dicoding.ipynb">
            <div className=' mb-5'>
                <img src={cuaca} alt="" className='object-cover h-96 w-96 mx-auto' />
            </div>
            </a>

            <a href="https://www.kaggle.com/code/zaiemmustaqiem/rice-disease-clasification-cnn">
            <div className=' mb-5'>
                <img src={padi} alt="" className='object-cover h-96 w-96 mx-auto' />
            </div>
            </a>

            <a href="">
            <div className=' mb-5'>
                <img src={ds1} alt="" className='object-cover h-96 w-96 mx-auto' />
            </div>
            </a>

            <div className=' mb-5'>
                <img src={ds2} alt="" className='object-cover h-96 w-96 mx-auto' />
            </div>

            <a href="https://www.kaggle.com/code/zaiemmustaqiem/monkeypox-using-cnn-with-tuning-layer">
            <div className=' mb-5'>
                <img src={monyet} alt="" className='object-cover h-96 w-96 mx-auto' />
            </div>
            </a>
                
            
            

        </div>
        <h1 className='text-2xl text-center font-semibold text-white mb-5 lg:font-normal'>see more on</h1>
        <a href="https://github.com/zaim1609"><button className='lg:ml-[670px] ml-48 py-3 rounded-xl px-10 bg-lime-300 text-2xl'>Git hup</button></a>
    </div>
  )
}

export default Portfolio