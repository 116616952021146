import React from 'react'
import Navbar from './navbar'
import Hero from './hero'
import Service from './Service'
import About from './About'
import Portfolio from './Portfolio'
import Contact from './Contact'


function App() {
  return (
  <div>
    <Navbar />
    <Hero />
    <Service/>
    <About/>
    <Portfolio/>
    <Contact/>

    
  </div>
  )
}

export default App