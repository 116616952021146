import React from 'react'

function Contact() {
  return (
    <div id='contack' className='py-5 bg-zinc-900 px-10'>
      <h1 className='text-6xl text-center font-semibold text-green-800 mb-5 mt-10 lg:mb-2'>Contack</h1>
      <h1 className='text-2xl text-center font-semibold text-white lg:font-normal'>if you interest working with me just contact me at</h1>

      <div className='grid grid-flow-row justify-center lg:grid-cols-3 py-32'>

        {/* card */}

        <div className='mb-10 '>
          <div className='bg-lime-300 w-40 h-40 rounded-full py-7 px-8 lg:mx-auto'>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-24 h-24">
  <path fill-rule="evenodd" d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z" clip-rule="evenodd" />
</svg>


          </div>
          <h1 className='mt-2 text-center text-3xl text-white'>Whatsup</h1>
        </div>
        
        <div className='mb-10'>
          <div className='bg-lime-300 w-40 h-40 rounded-full py-7 px-8 lg:mx-auto'>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-24 h-24">
  <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
  <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
</svg>
          </div>
          <h1 className='mt-2 text-center text-3xl text-white'>Email</h1>
        </div>

<a href="https://www.linkedin.com/in/zaim-mustaqim-301833234/">
        <div className='mb-10 '>
          <div className='bg-lime-300 w-40 h-40 rounded-full py-7 px-8 lg:mx-auto'>
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
width="100" height="100"
viewBox="0 0 24 24">
    <path d="M19,3H5C3.895,3,3,3.895,3,5v14c0,1.105,0.895,2,2,2h14c1.105,0,2-0.895,2-2V5C21,3.895,20.105,3,19,3z M9,17H6.477v-7H9 V17z M7.694,8.717c-0.771,0-1.286-0.514-1.286-1.2s0.514-1.2,1.371-1.2c0.771,0,1.286,0.514,1.286,1.2S8.551,8.717,7.694,8.717z M18,17h-2.442v-3.826c0-1.058-0.651-1.302-0.895-1.302s-1.058,0.163-1.058,1.302c0,0.163,0,3.826,0,3.826h-2.523v-7h2.523v0.977 C13.93,10.407,14.581,10,15.802,10C17.023,10,18,10.977,18,13.174V17z"></path>
</svg>
          </div>
          <a href="https://www.linkedin.com/in/zaim-mustaqim-301833234/"><h1 className='mt-2 text-center text-3xl text-white'>Linkind</h1></a>
        </div>
        </a>

      </div>

      <div>
        <h1 className='text-2xl text-center font-semibold text-white lg:font-normal'>Back to top</h1>
        <h1 className='text-white text-center'>copyrigth by zaim</h1>
        
      </div>


    </div>
  )
}

export default Contact