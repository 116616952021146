import React from 'react'
import lol from './images/lol.jpg'

function About() {
  return (
    <div id='about' className='py-3 bg-zinc-900 lg:h-screen h-[850px]'>
        <h1 className='text-6xl text-center font-semibold text-green-800 mb-10 lg:mb-24'>About</h1>
      
        <div className='grid grid-flow-row justify-center lg:grid-cols-2 px-10'>

            <div className='text-xl mb-12 font-semibold text-white lg:text-3xl lg:font-medium lg:my-auto'>
                <p>I am an Informatics Engineering student who is go deep into Data Scientist, Artificial Intelligent, Machine 
Learnig and Deep learning . A creative and innovative person in finding solutions to a problem so that I can get a good 
solution. Have good verbal and non verbal communication skills</p>
            </div>

            <div>
                <div className='w-96 h-96 lg:ml-64 mx-auto'>
                    <img src={lol} alt="" className='rounded-2xl shadow-xl shadow-gray-500'/>
                </div>
            </div>

        </div>
    </div>
  )
}

export default About