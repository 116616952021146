import React from 'react'

function Service() {
  return (
    <div id='services' className='py-5 bg-zinc-900 pt-20'>
      <h1 className='text-6xl text-center font-semibold text-green-800 mb-5 lg:mb-2'>services</h1>
      <h1 className='text-2xl text-center font-semibold text-white mb-14 lg:font-normal'>i can do what you wan</h1>

      <div className='grid grid-flow-row justify-center lg:grid-cols-3'>

        {/* card */}

        <div className='mb-10 '>
          <div className='bg-lime-300 w-40 h-40 rounded-full py-7 px-6 lg:mx-auto'>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-28 h-28">
  <path fill-rule="evenodd" d="M3 6a3 3 0 013-3h12a3 3 0 013 3v12a3 3 0 01-3 3H6a3 3 0 01-3-3V6zm4.5 7.5a.75.75 0 01.75.75v2.25a.75.75 0 01-1.5 0v-2.25a.75.75 0 01.75-.75zm3.75-1.5a.75.75 0 00-1.5 0v4.5a.75.75 0 001.5 0V12zm2.25-3a.75.75 0 01.75.75v6.75a.75.75 0 01-1.5 0V9.75A.75.75 0 0113.5 9zm3.75-1.5a.75.75 0 00-1.5 0v9a.75.75 0 001.5 0v-9z" clip-rule="evenodd" />
</svg>

          </div>
          <h1 className='mt-2 text-center text-3xl text-white'>Data Scient</h1>
        </div>
        
        <div className='mb-10'>
          <div className='bg-lime-300 w-40 h-40 rounded-full py-7 px-6 lg:mx-auto'>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-28 h-28">
  <path fill-rule="evenodd" d="M2.25 2.25a.75.75 0 000 1.5H3v10.5a3 3 0 003 3h1.21l-1.172 3.513a.75.75 0 001.424.474l.329-.987h8.418l.33.987a.75.75 0 001.422-.474l-1.17-3.513H18a3 3 0 003-3V3.75h.75a.75.75 0 000-1.5H2.25zm6.54 15h6.42l.5 1.5H8.29l.5-1.5zm8.085-8.995a.75.75 0 10-.75-1.299 12.81 12.81 0 00-3.558 3.05L11.03 8.47a.75.75 0 00-1.06 0l-3 3a.75.75 0 101.06 1.06l2.47-2.47 1.617 1.618a.75.75 0 001.146-.102 11.312 11.312 0 013.612-3.321z" clip-rule="evenodd" />
</svg>

          </div>
          <h1 className='mt-2 text-center text-3xl text-white'>Data Analist</h1>
        </div>

        <div className='mb-10 '>
          <div className='bg-lime-300 w-40 h-40 rounded-full py-7 px-6 lg:mx-auto'>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-28 h-28">
  <path d="M16.5 7.5h-9v9h9v-9z" />
  <path fill-rule="evenodd" d="M8.25 2.25A.75.75 0 019 3v.75h2.25V3a.75.75 0 011.5 0v.75H15V3a.75.75 0 011.5 0v.75h.75a3 3 0 013 3v.75H21A.75.75 0 0121 9h-.75v2.25H21a.75.75 0 010 1.5h-.75V15H21a.75.75 0 010 1.5h-.75v.75a3 3 0 01-3 3h-.75V21a.75.75 0 01-1.5 0v-.75h-2.25V21a.75.75 0 01-1.5 0v-.75H9V21a.75.75 0 01-1.5 0v-.75h-.75a3 3 0 01-3-3v-.75H3A.75.75 0 013 15h.75v-2.25H3a.75.75 0 010-1.5h.75V9H3a.75.75 0 010-1.5h.75v-.75a3 3 0 013-3h.75V3a.75.75 0 01.75-.75zM6 6.75A.75.75 0 016.75 6h10.5a.75.75 0 01.75.75v10.5a.75.75 0 01-.75.75H6.75a.75.75 0 01-.75-.75V6.75z" clip-rule="evenodd" />
</svg>

          </div>
          <h1 className='mt-2 text-center text-3xl text-white'>AI Engineer</h1>
        </div>

      </div>


    </div>
  )
}

export default Service