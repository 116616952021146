import React, {useState }from 'react'
import resum from './images/zaim.pdf'

function Navbar() {
  const [open, setOpen] = useState(false);
  return (
        <nav className="w-full fixed top-0 bg-zinc-900 z-40">
        <div className="container mx-auto py-5 flex justify-between items-center">
          <div>
              <h1 className='font-extrabold text-2xl text-green-600 '>Z_IM</h1>
          </div>
          <ul className="hidden md:flex space-x-10 text-gray-600 font-bold text-sm uppercase">
            <li>
              <a href="# " className="hover:text-slate-300">homepage</a>
            </li>
            <li>
              <a href="#services" className="hover:text-slate-300">Services</a>
            </li>
            <li>
              <a href="#about" className="hover:text-slate-300">About</a>
            </li>
            <li>
              <a href="#portfolio" className="hover:text-slate-300">Portfolio</a>
            </li>
            <li>
              <a href="#contack" className="hover:text-slate-300">Contack</a>
            </li>
          </ul>
          <ul className="hidden md:flex gap-5 text-gray-600 font-bold text-sm uppercase">
            <li>
              <a href={resum} download="Resume" className="bg-lime-300 py-2 px-7 rounded-lg hover:bg-amber-400">Resume</a>
            </li>
          </ul>
          <div className="space-y-1 md:hidden cursor-pointer absolute z-10 right-0" onClick={() => setOpen(!open)}>
              <div className="w-6 h-1 bg-black"></div>
              <div className="w-6 h-1 bg-black"></div>
              <div className="w-6 h-1 bg-black"></div>
          </div>
          <ul className={`md:hidden bg-white absolute left-0 top-0 w-full p-16 rounded-b-3xl space-y-16 text-black text-center duration-500 ${open ? "top-20 opacity-100"  : "top-[-100%] opacity-0"}`}>
          <li>
              <a href="#" className="hover:text-slate-300" >homepage</a>
            </li>
            <li>
              <a href="#">about me</a>
            </li>
            <li>
              <a href="#">work</a>
            </li>
            <li>
              <a href="#">services</a>
            </li>
            <li>
              <a href="#">contack</a>
            </li>
          </ul>

        </div>
      </nav>
  )
}

export default Navbar